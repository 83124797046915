const _temp0 = require("../components/Nav/navigation.js");

const _temp1 = require("../components/ImageBlock/imageBlock.js");


module.exports = {
  "components":   {
    "Nav":     {
      "navigation": _temp0
    },
    "ImageBlock":     {
      "imageBlock": _temp1
    }
  }
}